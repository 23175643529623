const getPwpOffer = (offers, price) => {
  const pwpOffers =
    offers && offers.filter(offer => offer?.type?.toUpperCase() === 'PWP')

  let pwpDiscount
  let isPwpApplicable

  if (pwpOffers && pwpOffers.length > 0) {
    const selectedPwpOffer = pwpOffers[0]
    isPwpApplicable = true
    if (selectedPwpOffer?.rule?.get) {
      const discountType = selectedPwpOffer.rule.get.t
      if (discountType === 'FIXED') {
        pwpDiscount = Number(price) - Number(selectedPwpOffer.rule.get.v || 0)
      } else if (discountType === 'ABSOLUTE_OFF') {
        pwpDiscount = selectedPwpOffer.rule.get.v || 0
      } else if (discountType === 'PERCENT_OFF') {
        pwpDiscount = (Number(selectedPwpOffer.rule.get.v || 0) / 100) * price
      }
    }
  }

  return { isPwpApplicable, pwpDiscount }
}

const offersExceptPwp = offers =>
  offers.filter(offer => offer?.offerType?.toUpperCase() !== 'PWP')

export const mergeSku = items => {
  const mergedSkuObj = items.reduce((mergedItems, item) => {
    const discountWithoutOffer =
      Number(item.orderDetails.discount) -
      offersExceptPwp(item.offer || []).reduce(
        (total, offer) =>
          total +
          Number(offer.discount) /
            Number(
              item.orderDetails.deliveredQuantity ||
                item.orderDetails.orderedQuantity
            ),
        0
      )
    if (mergedItems.hasOwnProperty(item.id)) {
      mergedItems[item.id].orderDetails = {
        ...mergedItems[item.id].orderDetails,
        deliveredQuantity:
          Number(mergedItems[item.id].orderDetails.deliveredQuantity || 0) +
          Number(item.orderDetails.deliveredQuantity),
        orderedQuantity:
          Number(mergedItems[item.id].orderDetails.orderedQuantity || 0) +
          Number(item.orderDetails.orderedQuantity),
        discount: Math.max(
          Number(mergedItems[item.id].orderDetails.discount),
          Number(discountWithoutOffer)
        ),
      }
      mergedItems[item.id].totalDiscount +=
        Number(item.orderDetails.discount) *
        Number(
          item.orderDetails.deliveredQuantity ||
            item.orderDetails.orderedQuantity
        )
      mergedItems[item.id].offer = [
        ...(mergedItems[item.id].offer || []),
        ...(item.offer || []),
      ]
    } else {
      mergedItems[item.id] = { ...item }
      mergedItems[item.id].orderDetails = {
        ...mergedItems[item.id].orderDetails,
        discount: discountWithoutOffer,
      }
      mergedItems[item.id].totalDiscount =
        Number(item.orderDetails.discount) *
        Number(
          item.orderDetails.deliveredQuantity ||
            item.orderDetails.orderedQuantity
        )
    }
    return mergedItems
  }, {})

  return Object.keys(mergedSkuObj).map(id => mergedSkuObj[id])
}

export const filterSimplePromotion = offers => {
  let sp
  const otherOffers = []
  offers.forEach(offer => {
    if (offer.metaData && offer.metaData.sapReference === 'ZSP') {
      sp = offer
    } else {
      otherOffers.push(offer)
    }
  })
  return { sp, otherOffers }
}

export default getPwpOffer
